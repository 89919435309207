import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../utils/base-data"
import { getBaseDataLawyer } from "../../../../redux/action/baseData/baseAction"
import { SET_SUCCESS_BASEDATA } from "../../../../redux/action/baseData/baseType"
import { useEffect } from "react"

const ConatctShareDropdownItem = (props) => {

    const dispatch = useDispatch()
    const baseDataInviteMessage = useSelector(state => state.baseData?.info?.config?.invite_message)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getInviteMessageFromBaseData()

    }, [])

    const getInviteMessageFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const sendLinkToPerson = () => {
        // send link
        const text = baseDataInviteMessage ? baseDataInviteMessage : 'نصب برنامه باوکیل - نسخه وکلا' + '\n http://bavakil.net'
        
        if( props.icon === 'telegram' ){
            window.open(`https://t.me/share/url?text=${encodeURIComponent(text)}`);
        }   

        else if( props.icon === 'whatsapp' ){
            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`);
        }

        else if( props.icon === 'eitaa' ){
            window.open(`https://eitaa.com/share/url?&text=${encodeURIComponent(text)}`);
        }
        
    }

    return (
        <button onClick={sendLinkToPerson} className="duration-300 flex flex-col text-sm items-center gap-2.5">
                
            <div className={`profile-${props.color} shadow-custom-md w-14 h-14 flex items-center justify-center border-2 border-gray-200 rounded-xl`}>
                <ReactSVG className="w-7 h-7" src={`${process.env.PUBLIC_URL}/assets/images/icons/${props.icon}.svg`} />
            </div>

            <span> {props.name} </span>

        </button>
    )

}

export default ConatctShareDropdownItem