import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../../../utils/base-data"
import { getBaseDataLawyer } from "../../../../../../redux/action/baseData/baseAction"
import { SET_SUCCESS_BASEDATA } from "../../../../../../redux/action/baseData/baseType"
import { useCookies } from "react-cookie"

const LoginFormTerms = () => {

    const dispatch = useDispatch()
    const baseDataConfigTermsUrl = useSelector(state => state.baseData?.info?.config?.terms_url)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {
        
        getLinksDataFromBaseData()

    }, [])

    const getLinksDataFromBaseData = async () => {

        if(baseDataLoaded) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    return (
        <p className="text-gray-500 text-sm mb-8">
            با ورود به باوکیل
            { baseDataConfigTermsUrl ? 
                <a href={baseDataConfigTermsUrl} target="_blank" className="text-primary"> قوانین و مقررات </a>
                :
                <span className="text-primary"> قوانین و مقررات </span>
            }
            را میپذیرم.
        </p>
    )

}

export default LoginFormTerms