import { ReactSVG } from "react-svg"

const HomeFollowSocial = () => {

    return (
        <section>   

            <a href="https://blog.bavakilapps.ir" target="_blank" className="bg-white shadow-custom-sm p-4 rounded-2xl flex items-center flex-wrap gap-4 justify-between border border-gray-200">

                <div className="flex flex-col gap-2">

                    <span className="text-primary font-medium"> آشنایی با باوکیل </span>

                    <span className="text-sm"> با کلیک در این بخش، به صفحه معرفی هدایت شوید </span>
                    
                </div>

                <ReactSVG
                    className="mr-auto"
                    src={`/assets/images/pictures/follow-social.svg`}
                />

            </a>

        </section>
    )

}

export default HomeFollowSocial