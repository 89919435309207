import { useEffect, useState } from "react"

const SelectClientTypeTrustImages = () => {

    const [link, setLink] = useState('')
    const [image, setImage] = useState('')
    const [code, setCode] = useState('')
    
    useEffect(() => {

        if( window.location?.host ===  'bavakil.net'){
            setLink('https://trustseal.enamad.ir/?id=495482&Code=SuDvCeAeK1nDeeNr7m37ao6oiEshoEMR')
            setImage('https://trustseal.enamad.ir/logo.aspx?id=495482&Code=SuDvCeAeK1nDeeNr7m37ao6oiEshoEMR')
            setCode('SuDvCeAeK1nDeeNr7m37ao6oiEshoEMR')
        }
        else {
            setLink('https://trustseal.enamad.ir/?id=487309&Code=L4VkDEv4AcYp3JON9zg8XBtMtthtMiUt')
            setImage('https://trustseal.enamad.ir/logo.aspx?id=487309&Code=L4VkDEv4AcYp3JON9zg8XBtMtthtMiUt')
            setCode('L4VkDEv4AcYp3JON9zg8XBtMtthtMiUt')
        }

    }, [])

    return (
        <a href={link} referrerPolicy='origin' target='_blank' className="w-24 h-24 mt-14 bg-white shadow-custom-md p-4 rounded-2xl flex items-center flex-wrap gap-4 justify-between border border-gray-200" >
            
            <img referrerPolicy='origin' src={image} alt='enamad image' code={code} />

        </a>
    )

}

export default SelectClientTypeTrustImages