import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"

const Switch = ({title, value, name, onUpdateSwitchValue, loading, description}) => {

    const updateSwitchValue = (props) => {
        onUpdateSwitchValue(!value)
    }

    return (
        <div className="flex w-full items-center flex-wrap justify-between">

            { !loading ? 
                
                <label htmlFor={`toggle-${name}`} className="flex items-center flex-wrap gap-6 justify-between w-full cursor-pointer">
                    
                    <div className="flex flex-col select-none">
                        
                        <div className="relative w-fit flex items-center flex-wrap">
                            
                            <span className="block mb-2"> {title} </span>
                            
                            <a href={'https://api.bavakilapps.ir/help'} target="_blank" className="5xm:absolute 5xm:translate-y-0 translate-y-1.5 -left-10 top-0 w-10 h-10 flex items-start justify-end">
                                
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/question.svg`}
                                    className="w-5 mt-0.5 ml-1 text-gray-500"
                                />

                            </a>

                        </div>
                        
                        <span className="block text-sm text-gray-600"> { description ? description : 'فقط به موکل هایی که مجوز مشاهده دارند.' } </span>

                    </div>

                    <div className="relative">
                        <input onChange={updateSwitchValue} type="checkbox" id={`toggle-${name}`} className="sr-only" checked={value ? value : false} />
                        <div className="dot-wrapper block bg-slate-400 w-14 h-7 duration-300 rounded-full"></div>
                        <div className="dot absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition"></div>
                    </div>

                </label>
                
                :
                
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-11 ml-2 text-gray-500"
                />

            }
                
        </div>
    )

}

export default Switch