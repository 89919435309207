import { add } from "date-fns-jalali"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const HomeWelcomeMessage = ({onSetWelcomeMessage}) => {

    const registerGiftPrice = useSelector(state => state.profile.profile?.lawyer_register_gift)
    const time = useSelector(state => state.profile.profile?.time)

    const [expirePlan, setExpirePlan] = useState()

    useEffect(() => {

        const welcomeMessage = localStorage.getItem('read-welcome-message')

        if(welcomeMessage){
            onSetWelcomeMessage(false)
            return
        }

        document.body.classList.add('modal-opened')

        return () => {
            document.body.classList.remove('modal-opened')
        }

    }, [])

    useEffect(() => {

        if(time){

            // set server time
            const serverObj = {
                year: Number(time?.slice(0, 4)),
                month: Number(time?.slice(5, 7)) - 1,
                day: Number(time?.slice(8, 10)),
                hours: Number(time?.slice(11, 13)) + 3,
                mins: Number(time?.slice(14, 16)) + 30,
                seconds: Number(time?.slice(17, 19))
            }

            if( serverObj.month >= 12 ){
                serverObj.year = serverObj.year + 1
                serverObj.month = serverObj.month - 12
            }
            if( serverObj.hours >= 24 ){
                serverObj.month = serverObj.month + 1
                serverObj.hours = serverObj.hours - 24
            }
            if( serverObj.mins > 60 ){
                serverObj.hours = serverObj.hours + 1
                serverObj.mins = serverObj.mins - 60
            }
            if( serverObj.seconds > 60 ){
                serverObj.mins = serverObj.mins + 1
                serverObj.seconds = serverObj.seconds - 60 
            }

            // server-time
            let serverTimeDate = new Date(serverObj.year, serverObj.month, serverObj.day , serverObj.hours, serverObj.mins, serverObj.seconds).valueOf()

            // get SYSTEM date
            const now = new Date().getTime()

            // calcuate different   
            const diffTime = serverTimeDate - now

            let expirePlan = new Date( (new Date() + diffTime))
            expirePlan = add(expirePlan, {days: 31}).toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit'})   

            setExpirePlan(expirePlan)
        }

    }, [time])

    const hideWelcomeMessage = () => {
        
        onSetWelcomeMessage(false)

        localStorage.setItem('read-welcome-message', true)

    }

    return (
        <>
            <div className="fixed left-0 right-0 top-0 bottom-0 h-fit m-auto w-full max-w-xl z-50 px-5">

                <div className="bg-white outline outline-8 outline-white/20 py-8 px-4 shadow-custom-xl shadow-white/40 rounded-3xl w-full flex-col flex">

                    <h3 className="font-medium 4xm:text-lg text-base leading-7 text-second text-center mb-6">  دوست عزیز ; به باوکیل خوش آمدید. <span className="translate-y-0.5 inline-block"> 🎉 </span> </h3>

                    <hr className="mb-6" /> 

                    <p className="text-gray-800 text-justify leading-7 mb-6"> <span className="inline-block translate-y-0.5"> 🎁 </span>  حساب شما به مبلغ <span className="text-second font-medium text-lg"> {registerGiftPrice ? Number(registerGiftPrice).toLocaleString('fa') : '۵۰۰۰'} </span> تومان <span className="text-black font-medium"> هدیه </span> اولیه شارژ شد. </p>
                    
                    <p className="text-gray-800 text-justify leading-7 mb-6"> <span className="inline-block translate-y-0.5"> 🎁 </span>  <span className="text-black font-medium"> اشتراک یکماهه رایگان </span>  تا تاریخ <span className="text-second font-medium"> {expirePlan} </span> برای شما فعال شد. </p>

                    <hr className="mb-6" /> 

                    <p className="text-justify leading-7 text-gray-600 mb-14"> 📍 شما میتوانید از منوی راست، خرید اشتراک، از اخرین وضعیت اشتراک خود مطلع شده یا اقدام به <Link to={'/plans'} className="text-second font-medium">خرید اشتراک</Link> جدید نمایید. </p>

                    <button onClick={hideWelcomeMessage} className="btn font-medium outline outline-4 outline-primary/20"> مطالعه شد </button>

                </div>

            </div>

            <div onClick={() => onSetWelcomeMessage(false)} className="backdrop bg-black/80"> </div>
        </>
    )

}

export default HomeWelcomeMessage