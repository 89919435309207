import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getWalletInfo } from "../../../redux/action/wallet/action"
import { useCookies } from "react-cookie"
import { ReactSVG } from "react-svg"
import { getPeymentedStatus, savePeymented } from "../../../utils/paymented"
import { getProfileInfo } from "../../../redux/action/profile/profileAction"

const PlansHeaderAction = (props) => {

    const dispatch = useDispatch()
    const plansError = useSelector(state => state.plans.error)
    
    const walletAmount = useSelector(state => state?.wallet?.wallet?.balance)
    const walletLoading = useSelector(state => state?.wallet.loading)
    const walletLoaded = useSelector(state => state?.wallet.loaded)

    const [havePayment, setHavePayment] = useState(false)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!walletLoaded && !walletLoading){
            getWalletData()
        }
        
        const paymentStatus =  getPeymentedStatus()

        if(paymentStatus){
            setHavePayment(true)
        }


    }, [])

    const getWalletData = async () => {
        await dispatch( await getWalletInfo(cookies.authToken) )
    }

    const reloadPlansData = async () => {
        
        // reload profile info
        if(havePayment){

            // update status
            const data = await dispatch( await getProfileInfo(cookies.authToken) )
            
            if(data){
                
                savePeymented(false)
                setHavePayment(false) 
                
                // update wallet    
                getWalletData()
            }            

        }

        else {

            props.onGetPlansData()

        }

    }

    return (
        <>
            { plansError || havePayment ? 

                <button onClick={ reloadPlansData } className="w-10 h-8 flex items-center justify-end">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/turn-back.svg`} 
                        className="w-5 text-primary" 
                    />
                </button>

                :
                
                <div className="flex items-center gap-x-1">
                    <span className="text-second text-xs 45xm:block hidden"> تومان </span>
                    { walletAmount?.total ? 
                        <span className="text-second 6xm:text-base text-sm font-medium"> {  Number(walletAmount?.total).toLocaleString('fa') } </span>
                        :
                        !walletLoading ?
                            <span className="text-second 6xm:text-base text-sm font-medium"> ۰ </span>
                        :
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} 
                            className="w-6 text-second" 
                        />
                    }
                    <span className="text-second 6xm:text-base text-sm"> :موجودی </span>
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/wallet.svg`} 
                        className="w-5 text-second 5xm:block hidden" 
                    />
                </div>

            }
        </>
    )

}

export default PlansHeaderAction