import { useNavigate } from "react-router-dom"

const SelectClientTypeButton = ({type}) => {


    const navigate = useNavigate()

    const navigateToAuthLogin = () => {

        if(type === 0){

            localStorage.setItem('selected-client-type', 'lawyer')

            navigate('/auth/login', {replace: true})

        }

        else {
            localStorage.removeItem('selected-client-type')

            window.location.href = 'https://client.bavakil.net/auth/login'
        }

    }

    return (
        <div className={`${'static mt-6'} bottom-6 px-1 w-full max-w-2xl`}>
            <button onClick={navigateToAuthLogin} className="rounded-lg mt-auto text-lg font-medium bg-gradient-to-tr from-[#0fbc63] bg-[#26a256] shadow-custom-sm shadow-[#26a256]/20 text-white py-3 px-4 w-full"> ادامه </button>
        </div>
    )

}

export default SelectClientTypeButton